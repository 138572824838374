import tlm from './images/tlm.png';
import garyjokes from './images/garyjokes.png';
import germanfest from './images/germanfest.png';
import ghs from './images/ghs.png';
import pokebattle from './images/pokebattle.png';
import rogue from './images/rogue.png';
import './Portfolio.css';


export default function Portfolio() {
    return (
        <div className="Portfolio">
            <h1>Websites</h1>
            <a href="https://tlm-web.web.app" target='_blank'>
                <img src={tlm} alt='Triad Lifestyle Medicine' />
                <p>Triad Lifestyle Medicine<br />(As part of the team at Go Forth Marketing)</p>
            </a>
            <a href="https://www.germanfest.org" target='_blank'>
                <img src={germanfest} alt='Gary Jokes' />
                <p>Germanfest</p>
            </a>
            
            <a href="https://www.germanheritagesociety.com" target='_blank'>
                <img src={ghs} alt='German Heritage Society' />
                <p>German Heritage Society</p>
            </a>

            <h1>Prototype Games</h1>
            <a href="https://pokebattle.click" target='_blank'>
                <img src={pokebattle} alt='Gary Jokes' />
                <p>Poke Battle</p>
            </a>
            <a href="https://whinyjack.itch.io/rogue-ish" target='_blank'>
                <img src={rogue} alt='Rogue-ish' />
                <p>Rogue-ish</p>
            </a>
            <a href="https://garyjokes.click" target='_blank'>
                <img src={garyjokes} alt='Gary Jokes' />
                <p>Gary Jokes <br />(Dad joke generator)</p>
            </a>
            </div>
    )
}