import logo from './images/jackLogo.png';
import './CharacterAbout.css';
import CharacterAbilities from './CharacterAbilities';
import CharacterBio from './CharacterBio';

export default function CharacterAbout(){
    return(
        <div className='CharacterAbout'>
            <div className='heading'>
            <h1>Jack Pennington</h1>
            <sub className='sub'>Character Name</sub></div>
            <div className='heading'>
            <h2>Web Dev / Software Solutions / Maker / Hacker</h2>
            <sub className='sub'>Character Class</sub></div>
            <img src={logo} alt='logo' />
            <CharacterAbilities />
            <CharacterBio />
        </div>
        
    );
}