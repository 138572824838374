import './CharacterSkills.css'

export default function CharacterSkills() {
    return(
        <div className="CharacterSkills">
            <h1>Skills</h1>
           <table>
                <tr>
                    <td className='cat'>Languages</td>
                    <td>Javascript</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Typescript</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Python</td>
                </tr>
                <tr>
                    <td></td>
                    <td>C++ / C#</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Arduino</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Java</td>
                </tr>
                <tr>
                    <td></td>
                    <td>PHP</td>
                </tr>
                <tr>
                    <td></td>
                    <td>HTML/CSS</td>
                </tr>
                <tr><th><hr /></th><th><hr /></th></tr>
                <tr>
                    <td className='cat'>Frontend</td>
                    <td>Node.js</td>
                </tr>
                <tr>
                    <td></td>
                    <td>React</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Angular</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Next.js</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Bootstrap</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Tailwind</td>
                </tr>
                <tr><th><hr /></th><th><hr /></th></tr>
                <tr>
                    <td className='cat'>Backend</td>
                    <td>Firebase</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Firestore</td>
                </tr>
                <tr>
                    <td></td>
                    <td>AWS</td>
                </tr>
                <tr>
                    <td></td>
                    <td>MongoDB</td>
                </tr>
                <tr>
                    <td></td>
                    <td>MySQL</td>
                </tr>
                <tr><th><hr /></th><th><hr /></th></tr>
                <tr>
                    <td className='cat'>Software</td>
                    <td>GitHub</td>
                </tr>
                <tr>
                    <td></td>
                    <td>VS Code</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Linux</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Unity</td>
                </tr>
                <tr>
                    <td></td>
                    <td>CAD</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Blender</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Adobe Creative Cloud</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Inkscape</td>
                </tr>
                <tr>
                    <td></td>
                    <td>GIMP</td>
                </tr>
                <tr><th><hr /></th><th><hr /></th></tr>
                <tr>
                    <td className='cat'>Misc.</td>
                    <td>OpenAI API</td>
                </tr>
                <tr>
                    <td></td>
                    <td>ChatGPT Prompting</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Google Ads</td>
                </tr>
                
           </table>

           <h1>Education</h1>
           <table>
                <tr>
                    <td className='cat'>College</td>
                    <td>UNC School of the Arts, B.F.A. - Filmmaking</td>
                </tr>
                <tr>
                    <td className='cat'>Certifications</td>
                    <td>Shopify App Deveopment</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Google Ads</td>
                </tr>
                <tr>
                    <td></td>
                    <td><a href='https://coursera.org/share/ee8ed66ebc4742642ee85dc60a3d1b45' target='_blank'>Google IT Support</a></td>
                </tr>
                <tr>
                    <td></td>
                    <td><a href='https://coursera.org/verify/FQNACHJTHNDD' target='_blank'>Computer Networking</a></td>
                </tr>
                <tr>
                    <td></td>
                    <td><a href='https://coursera.org/verify/PDQW2TGK42NA' target='_blank'>IT Security</a></td>
                </tr>
                </table>

                {/* <img src='https://placehold.co/500?text=childish+illustration+of+jack+the+code+mage+battling+the+evil+forces+of+Cyberspherea' alt='childish illustration of jack the code mage battling the evil forces of Cyberspherea'/>
                <p>Me, defeating a computer virus. Pwa-chow!</p> */}
        </div>

    )
}