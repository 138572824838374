import "./CharacterBio.css";

export default function CharacterBio() {
  return (
    <div className="CharacterBio">
      <h1>Character Bio</h1>
      <div className="bio">
        <p>
          {" "}
          Jack was born in the land of Cyberspherea, a realm where technology
          and magic coexist in harmony. As a child, he was fascinated by the
          possibilities of the digital world, spending countless hours tinkering
          with his computer and exploring the far reaches of the internet.
        </p>

        <p>
          As he grew older, Jack discovered a new passion: filmmaking. He was
          drawn to the magic of storytelling and the power of visual media to
          inspire and move people. He pursued his dreams, honing his craft as a
          filmmaker and creating works that earned him acclaim throughout the
          kingdom.
        </p>

        <p>
          However, Jack's love for technology never waned. He saw the potential
          of the digital realm to revolutionize the world, and he
          began experimenting with code and web development. Before long, he had
          become a master Code Mage, able to wield technology as if it were
          magic.
        </p>

        <p>
          Today, Jack is renowned throughout Cyberspherea as a visionary web
          developer, with a reputation for creating innovative and cutting-edge
          solutions for businesses of all sizes. His background in filmmaking
          gives him a unique perspective on the world of web development,
          allowing him to create immersive and engaging experiences that
          captivate audiences and drive results.
        </p>

        <p>
          Jack's mastery of code and technology is matched only by his
          creativity and imagination. He is always exploring new ideas and
          pushing the boundaries of what is possible in the digital realm. He
          believes that technology has the power to transform the world, and he
          is determined to use his skills to make that transformation a reality.
        </p>

        <p>
          So if you're looking for a web developer who can bring a touch of
          magic to your digital presence, look no further than Jack. His journey has taken him to the
          forefront of the world of web development in Cyberspherea. He's ready
          to help you create a digital experience that is truly unforgettable.
        </p>
      </div>
    </div>
  );
}
