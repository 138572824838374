import Character from "./Character";
import "./App.css";
import paperBG from './images/paperBG.jpg';
import Footer from "./Footer";
import { app } from './firebase';

function App() {
  return (
    <div className="App" style={{background: `url(${paperBG})`, backgroundSize: '100vw 100vh', backgroundAttachment: 'fixed'}}>
      <Character />
      
      <Footer />
    </div>
  );
}

export default App;
