import './CharacterAbilities.css'

export default function CharacterAbilities(){
    return(
        <div className='CharacterAbilities'>
            <ul>
                <li>
                     <div className='mod'>10</div><div className='ability'>STR</div>
                    </li>
                <li> <div className='mod'> 8</div><div className='ability'>DEX</div> </li>
                <li>  <div className='mod'>11</div><div className='ability'>CON</div></li>
                <li>  <div className='mod'>18</div><div className='ability'>INT</div></li>
                <li>  <div className='mod'>15</div><div className='ability'>WIS</div></li>
                <li> <div className='mod'>16</div><div className='ability'>CHA</div> </li>
            </ul>

        </div>
    )
}