import './Character.css';
import CharacterAbout from './CharacterAbout';
import CharacterAbilities from './CharacterAbilities';
import CharacterSkills from './CharacterSkills';
import Portfolio from './Portfolio';

export default function Character() {
    return (
        <div className="Character" >
            <CharacterAbout />
            {/* <CharacterAbilities /> */}
            <CharacterSkills />
            <Portfolio />
        </div>
    )
}